 /* Projects */
#projectShowcase {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 10vh 0;
}

.recentWorks {
    margin: 10px;;
    line-height: 1.6;
}

.recentWorks a {
    font-size: inherit;
}

.showcase {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 8%;
    max-width: 100%;
}

#projectShowcase h2 {
    text-align: center;
}

.moreProjectBtn {
    width: fit-content;
    align-self: center;
    transition: transform 0.2s ease-in-out;
}

.moreProjectBtn:hover {
    cursor: pointer;
    transform: scale(1.2);
}