#about {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 10vh 0;
}

.aboutText {
    line-height: 1.6;
    padding: 20px;
}

.aboutText p {
    font-size: 1.2rem;
}

@media screen and (min-width: 800px) {
    .aboutText {
    width: 70vw;
    }
    .aboutText p {
        font-size: 1.3rem;
    }
}