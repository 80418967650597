/*Footer*/
#footer {
    background-color: var(--dark-BG);
    min-height: 15vh;
    padding: 30px;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    align-items: center;
}

hr {
    width: 70%;
}

.contact-icon-set {
    display: flex;
}
#footer > p {
    color: white;
}


@media screen and (min-width: 800px) {
    #footer {
        min-height: 10vh;
        padding: 10px;
        display: flex;
        flex-flow: row wrap;
    }

    #footer > p {
        justify-self: flex-end;
        align-self: flex-end;
        margin-bottom: 10px;
    }    
}