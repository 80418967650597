/* Inidividual Project Component */
.project {
    align-self: flex-start;
    width: 350px;
    height: 400;
    margin: 20px 10px;
    padding: 10px;
}

.project > * {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.project-preview {
    width: min(70vw, 350px);
    height: min(40vw, 200px);
    background-color: #3F90BE;
    border-radius: 25px;
    margin: auto;
    transition: all 200ms ease-in-out 100ms;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.project-preview:hover,
.project-preview:focus,
.link-to-project:focus + .project-preview,
.project-preview:focus-within {
    transform: scale(1.2);
    cursor: pointer;
}

.projectLink{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0%;
    border: inherit;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms ease-in-out 100ms;
    color: white;
}

.projectLink:hover,
.project-preview:focus .projectLink,
.projectLink:focus-within {
    backdrop-filter: blur(3px) brightness(50%);
    opacity: 100%;
}

.projectLink a {
    font-weight: 500;
}

.projectLink a ~ a::before {
    content: "|";
    color: white;
    margin: 20px;
}

.project p, 
.project h3 {
    text-align: center;
}

.project > p {
    font-size: 1.2rem;
    line-height: 1;
    max-width: min(70vw, 400px);
}

.techs span {
    font-size: 1.1rem;
    line-height: 2;
}