:root {
  /* Color Variables */
  --left-border-color: #b5d5f1;
  --right-retangle-color: #AFAFF9;
  --top-border-color: #68e1ff;

  --dark-blue: #072A40;
  --mid-blue: #009bca;
  --light-aqua: #18B7BE;
  --light-grey: #F9F7F0;

  --active-link-color: #6518be;

  --dark-grey: rgb(50, 50, 50);

  --light-BG: #f4fbfc;
  --dark-BG: #005B94;
  --social-border-color: rgb(255, 255, 255);
  --link-color: #0620A2;
  --typing-color: #004CC7;
  --focused-color: rgb(7, 177, 7);
  --footer-color: #3e7ea5;
  --hero-nav-color: #002157;
  --nav-logo-bg: #005B94;

  --nav-bg-hover: #8AB4CC;
  --nav-bg-active: #1c3c4e;
  --nav-active-link: #001166;

  /* Nav height */
  --nav-bar-height: calc(50px + 1vh);
}

/*Additional Reset and Initialzie */
html{
  scroll-behavior: smooth;
  scroll-padding-top: var(--nav-bar-height);
  max-width: 100vw;
  background-color: aliceblue;
}

button {
  border: none;
  width: auto;
  padding: 0 20px;
  height: 50px;
  margin: 5px;
  border-radius: 25px;
}

/* Fonts  */
h1 {
  font-family: 'JetBrains Mono', monospace;
}

h2, h3 {
  font-family: 'Raleway', sans-serif;
  text-align: center;
}

h2 {
  font-size: 2.3em;
  font-weight: 700;
  padding: 10px;
}

h3 {
  font-size: 1.9rem;
  font-weight: 500;
}

h4 {
  font-size: 1.7rem;
}

p, a, span {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
}
p {
  font-size: 1.3rem;
}

a {
  color: var(--link-color);
  text-decoration: none;
  font-style: italic;
}

a:hover {
  filter: brightness(150%);
}

a:active {
  color: white;

}

em {
  font-style: italic;
}

strong {
font-weight: 500;
}

/* <Main> Content */
main {
  margin-top: var(--nav-bar-height); 
  min-height: 800px;
}

main > section:nth-of-type(2n) {
  background-color: var(--dark-BG);
  color: white;
}

main > section:nth-of-type(2n + 1) {
  background-color: var(--light-BG);
  color: black;
}
