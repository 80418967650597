.skillSection > * {
    margin: 10px;
    text-align: center;
}

.skill-tags {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
}

@media screen and (min-width: 800px) {
    .skillSection {
        margin: 20px 0;
    }
}