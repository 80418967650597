#skills {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10vh 0;
}

.sectionDiv {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

@media screen and (min-width: 800px) {
    .sectionDiv {
        width: 80%;
    }
}