#contactPage {
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-between;
    align-items: space-between;
    min-height: 800px;
    max-width: 100%;
}
.contactMain {
    width: 100%;
    align-self: center;
    justify-self: center;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.contactMain p {
    width: 80%;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 20px;
}
@media screen and (min-width: 900px) {
    #contactPage {
        flex-wrap: nowrap;
    }
    .contactMain {
        width: 60%;
    }
}

@media screen and (max-aspect-ratio: 7/10) and (min-width: 700px){
    #contactPage {
        margin-top: var(--nav-bar-height);
        flex-direction: row wrap;
        min-height: 86vh;
        justify-content: flex-start;
    }
    .contactMain {
        width: 100%;
    }
}