.contactInfo {
    background-color: #a4ceff;
    min-height: 20vh;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
    line-height: 1.6;
    padding: 30px;
}

.contactfaIcon {
    color: var(--dark-blue);
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

.contactInfo a {
    font-size: 0.9em;
}

@media screen and (min-width: 900px) {
    .contactInfo {
        width: 40%;
        height: 100vh;
        margin: 0;
    }
}

@media screen and (max-aspect-ratio: 7/10) and (min-width: 700px){
    .contactInfo {
        width: 100%;
        height: auto;
    }
}