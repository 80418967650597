 /*Header*/
#header {
    border-bottom: 1px solid gray;
    height: var(--nav-bar-height);
    min-height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100vw;
    background-color: rgba(240, 248, 255, 0.9);
    padding: var(--nav-bar-padding);
    z-index: 4;
}

#header > *{
    margin: 0 30px;
}

#siteLogo {
    font-family: 'JetBrains Mono', monospace;
    font-size: 2.1rem;
    color: black;
    font-style: normal;
}

#siteLogo span {
    font: inherit;
    height: 100%;
    padding: 8px 2px;
    display: inline-block;
    background-color: var(--nav-logo-bg);
    color: white;
}


#burgerNav {
    display: none;
    background: url('../../assets/icons/bars.svg') no-repeat;
    height: 40px;
    width: 40px;
    padding: 0;
    border-radius: 0;
}

#burgerNav:hover {
    cursor: pointer;
}

/*Animation*/
#siteLogo::after{
    content: "_";
    color: darkblue;
    animation: blinkingUnderscore 1s infinite;
}

@keyframes blinkingUnderscore {
    0%{
        visibility: hidden;
    }
    50%{
        visibility: hidden;
    }
    100%{
        visibility: visible;
    }
}

/* Media Queries */

@media screen and (max-aspect-ratio: 8 / 10), (max-width: 900px) {
    #siteLogo {
        font-size: calc(1rem + 2vmin);
    }
    /* For mobile Display */
    #burgerNav {
        display: block;
    }
    #navMenu {
        display: none;
        position: absolute;
        left: 0;
        top: calc(var(--nav-bar-height) + 1px);
        flex-flow: column wrap;
        background-color: aliceblue;
    }
    #navMenu > ul {
        width: 100vw;
        flex-flow: column wrap;
    }
    #navMenu > ul li{
        margin: 0;
    }
    .navLink {
    width: 100vw;
    }
}