.contactForm {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-self: center;
    max-width: 100%;
    min-height: 100%;
}

.contactForm * {
    margin: 10px;
}

.contactInput {
    border: none;
    border-radius: 0;
    padding: 10px;
    position: relative;
    background-color: transparent;
    backdrop-filter: brightness(95%);
    border-bottom: 2px solid var(--link-color);
    transition: border-bottom 0.3s ease-in-out;
}

textarea.contactInput {
    resize: none;
}

.contactInput:focus {
    outline: none;
    background-color: blanchedalmond;
}

.contactInput:focus + .focusBorder {
    position: relative;
    top: -22px;
    border-bottom: 2px solid var(--focused-color);
    animation: focusedInput 0.7s ease-in-out forwards;
}


textarea.contactInput:focus ~ .focusBorder {
    position: relative;
    top: -22px;
    border-bottom: 2px solid var(--focused-color);
    animation: focusedInput 0.7s ease-in-out forwards;
}

@keyframes focusedInput {
    0%{
        opacity: 0;
        width: 0%;
    }
    20%{
        opacity: 1;
    }
    90%{
        width: 90%;
    }
    100%{
        width: inherit;
    }
}


.required-asterisk {
    color: red;
    margin: 0;
}

.infoRow {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 0;
}

.infoCol {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.contactForm input[type=submit] {
    width: fit-content;
    font-size: 1.3rem;
    align-self: center;
    padding: 10px 20px;
    background-color: var(--light-aqua);
    border: none;
    color: white;
}
.contactForm input[type=submit]:hover,
.contactForm input[type=submit]:focus {
    cursor: pointer;
    background-color: var(--mid-blue);
    filter: brightness(120%);
} 

.contactForm input[type=submit]:active {
    color: black;
    filter: brightness(80%);
}