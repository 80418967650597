.navLink {
    display: inline-block;
    color: rgb(20, 24, 29);
    padding: 10px;
    font-weight: 600;
}
.navLink:hover {
    background-color: var(--nav-bg-hover);
}
.navLink:active {
    color: white;
    background-color: var(--nav-bg-active);
}

#nav-Links {
    display: inline-flex;
}

.navLink {
    display: inline-block;
    color: rgb(20, 24, 29);
    font-weight: 400;
    font-style: normal;
    margin: 10px;
}

.active-link {
    font-weight: 600;
    color: var(--nav-active-link);
}