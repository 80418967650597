/* Font */
h1 {
    font-size: calc(30px + 1vmin);
    font-weight: 600;
}

/* Hero */
#hero {
    width: 100vw;
    max-width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    min-height: min(1000px, 100vh);
    grid-template-columns: 0.2fr repeat(3, 1fr)  0.2fr; 
    grid-template-rows:  0.5fr 1fr 0.5fr; 
    gap: 10px;
    position: relative;
}

.hero-content {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    z-index: 3;
    grid-area: 1 / 2 / span 3 / span 3;
    justify-self: center;
    margin-bottom: 10vh;
}

#heroHr {
    align-self: flex-start;
    width: 100%;
    animation: extendRight 0.5s ease-in-out forwards;
}

/* Text */

#hero-text{
    padding: 20px;
    text-align: left;
}

#hero-text > h1, #hero-text p {
    margin: 5px 0;
    z-index: 2;
}

#heroDescription {
    font-size: 1.3rem;
    line-height: 1.7;
}

/* Typed out name */

#typingEffect {
    max-width: fit-content;
    display: inline-flex;
    font: inherit;
    color: var(--typing-color);
    white-space: nowrap;
    overflow: hidden;
    animation: typing 2s steps(7) infinite alternate;
}

#underscore {
    font-size: inherit;
    animation: blinkingUnderscore 1s linear infinite;
}

/* Nav button Section */

#hero-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    animation: FadeIn 1.5s ease-in-out forwards;
}

/* Animations */

@keyframes extendRight {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

@keyframes FadeIn {
    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}

@keyframes typing {
    0% {
        width: 0;
    }
    20% {
        width: 0;
    }
    40%{
        width: 25%;
    }
    80% {
        width: 50%;
    }
    100% {
        width: 50%;
    }
}
@keyframes blinkingUnderscore {
    0%{
        visibility: hidden;
    }
    50%{
        visibility: hidden;
    }
    100%{
        visibility: visible;
    }
}
/* Media Queries */

@media screen and (min-width: 800px) {
    #hero {
        padding: 0;
    }

    #hero-text {
        width: min(70%, 70vw);
        text-align: left;
    }
}
