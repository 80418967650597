 /*Skill Tag*/
.skill-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid var(--dark-blue);
    height: 30px;
    min-width: 40px;
    margin: 5px 10px;
    backdrop-filter: brightness(90%);
}

.skill-icon {
    --width: 30px;
    height: var(--width);
    width: var(--width);
    margin-right: 5px;
}